import React, { memo } from "react"
import { css } from "@emotion/react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const BaseImage = memo(
  function BaseImage(props) {
    const { src, ...other } = props

    css.image = css`
      vertical-align: middle;
    `

    return (
      <GatsbyImage image={getImage(src)} {...other} alt="" css={css.image} />
    )
  },
  () => {
    return true
  }
)

export default BaseImage
