import { createSlice } from "@reduxjs/toolkit"

export const themeSlice = createSlice({
  name: "theme",
  initialState: {
    current: "",
  },
  reducers: {
    setThemeCurrent: (state, action) => {
      state.current = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setThemeCurrent } = themeSlice.actions

export default themeSlice.reducer
