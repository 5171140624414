import { createSlice } from "@reduxjs/toolkit"

export const menuSlice = createSlice({
  name: "menu",
  initialState: {
    active: false,
  },
  reducers: {
    setMenuActive: (state, action) => {
      state.active = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setMenuActive } = menuSlice.actions

export default menuSlice.reducer
