import React from "react"
import { SwitchTransition, CSSTransition } from "react-transition-group"
import { useDispatch, useSelector } from "react-redux"

import { setState, setDuration } from "../store/transition"

export default function TransitionPage({ children, location }) {
  const dispatch = useDispatch()
  const transitionDuration = useSelector(state => state.transition.duration)
  const transitionDurationDefault = useSelector(state => state.transition.durationDefault)

  return (
    <SwitchTransition>
      <CSSTransition
        key={location.pathname}
        classNames="page"
        timeout={transitionDuration}
        unmountOnExit
        appear={true}
        onExiting={() => {
          dispatch(setState("exiting"))
        }}
        onExited={() => {
          dispatch(setState("exited"))
          dispatch(setDuration(transitionDurationDefault))
        }}
        onEntering={() => {
          dispatch(setState("entering"))
        }}
        onEntered={() => {
          dispatch(setState("entered"))
        }}
      >
        <div>{children}</div>
      </CSSTransition>
    </SwitchTransition>
  )
}
