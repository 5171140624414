import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { css, useTheme } from "@emotion/react"
import { fluidRange } from "polished"
import Headroom from 'react-headroom'

import BaseSvg from "../components/BaseSvg"
import TheHeaderMenu from "../components/TheHeaderMenu"
import TheHeaderHeadroom from "../components/TheHeaderHeadroom"

const TheHeader = ({ siteTitle }) => {
  const theme = useTheme()

  css.logo = css`
    color: inherit;
  `

  return (
    <header>
      <TheHeaderHeadroom disable={true}>
        <Link css={css.logo} to="/">
          <BaseSvg src="logo-mark" />
        </Link>
      </TheHeaderHeadroom>
      <TheHeaderMenu />
    </header>
  )
}

TheHeader.propTypes = {
  siteTitle: PropTypes.string,
}

TheHeader.defaultProps = {
  siteTitle: ``,
}

export default TheHeader
