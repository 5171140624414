import React from "react"
import { css } from "@emotion/react"

import Plus from "../svg/plus.inline.svg"
import QuoteLeft from "../svg/quote-left.inline.svg"
import QuoteRight from "../svg/quote-right.inline.svg"
import LogoMark from "../svg/logo-mark.inline.svg"
import Dribbble from "../svg/dribbble.inline.svg"
import Instagram from "../svg/instagram.inline.svg"
import Linkedin from "../svg/linkedin.inline.svg"
import GraphicPixel from "../svg/graphic-pixel.inline.svg"
import ArrowDown from "../svg/arrow-down.inline.svg"
import ArrowRight from "../svg/arrow-right.inline.svg"
import Play from "../svg/play.inline.svg"
import Pause from "../svg/pause.inline.svg"
import Subscribe from "../svg/subscribe.inline.svg"
import Menu from "../svg/menu.inline.svg"
import Close from "../svg/close.inline.svg"
import LogoElement from "../svg/logo-element.inline.svg"

const componentList = {}

componentList["plus"] = Plus
componentList["quote-left"] = QuoteLeft
componentList["quote-right"] = QuoteRight
componentList["logo-mark"] = LogoMark
componentList["dribbble"] = Dribbble
componentList["instagram"] = Instagram
componentList["linkedin"] = Linkedin
componentList["graphic-pixel"] = GraphicPixel
componentList["arrow-down"] = ArrowDown
componentList["arrow-right"] = ArrowRight
componentList["play"] = Play
componentList["pause"] = Pause
componentList["subscribe"] = Subscribe
componentList["menu"] = Menu
componentList["close"] = Close
componentList["logo-element"] = LogoElement

const AppBaseSvg = props => {
  const { src, ...other } = props
  const Component = componentList[src]

  css.svg = css`
    &[fill*="#"] {
      fill: currentColor;
    }

    &[stroke*="#"] {
      stroke: currentColor;
    }

    * {
      &[fill*="#"] {
        fill: currentColor;
      }

      &[stroke*="#"] {
        stroke: currentColor;
      }
    }
  `

  return <Component css={css.svg} {...other} />
}

export default AppBaseSvg
