import React, { useRef, useEffect, createRef } from "react"
import { css, useTheme } from "@emotion/react"
import { fluidRange, rgba } from "polished"
import { gsap, MorphSVGPlugin } from "gsap/all"
import Headroom from 'react-headroom'

import BaseSvg from "../components/BaseSvg"
import TheHeaderHeadroom from "../components/TheHeaderHeadroom"

if (typeof window !== "undefined") {
  gsap.registerPlugin(MorphSVGPlugin)
}

const TheHeaderMenuToggle = props => {
  const ref = useRef(null)
  const theme = useTheme()
  const { handleMenuToggle, isMenuActive, ...other } = props

  useEffect(() => {
    const element = ref.current
    const close = element.querySelector(".close path")
    const menu = element.querySelector(".menu path")
    const text = element.querySelectorAll(".text")

    if (isMenuActive) {
      const timeline = gsap.timeline({})

      timeline
        .fromTo(menu, { morphSVG: menu }, { morphSVG: close, duration: 0.3 }, 0)
        .fromTo(text, { y: "0%" }, { y: "-100%", duration: 0.3 }, 0)
    } else {
      const timeline = gsap.timeline({})

      timeline
        .fromTo(menu, { morphSVG: close }, { morphSVG: menu, duration: 0.3 }, 0)
        .fromTo(text, { y: "-100%" }, { y: "0%", duration: 0.3 }, 0)
    }
  }, [isMenuActive])

  css.button = css`
    ${theme.mixins.button}

    float: right;
    font-weight: ${theme.fontWeight.bold};
    color: ${theme.colors.typeAndTixel.one};
  `

  css.buttonHeader = css`
    position: relative;
    top: -4px;
    color: inherit;

    &:before {
      content: "";
      position: absolute;
      top: -20px;
      right: -20px;
      bottom: -20px;
      left: -20px;
    }

    &:hover {
      &:before {
        top: -80px;
        right: -80px;
        bottom: -80px;
        left: -80px;
      }
    }
  `

  css.buttonText = css`
    position: relative;
    overflow: hidden;
    pointer-events: none;
  `

  css.buttonTextClose = css`
    position: absolute;
    top: 100%;
    left: 0;
  `

  css.buttonIcon = css`
    width: 30px;
    margin-left: 15px;
    pointer-events: none;
  `

  css.icon = css`
    vertical-align: middle;
  `

  css.close = css`
    display: none;
  `

  return (
    <TheHeaderHeadroom alignmentHorizontal="right">
      <button
        ref={ref}
        {...other}
        css={[css.button, css.buttonHeader]}
        className="cursor cursor--magnetic"
        onClick={handleMenuToggle}
      >
        <div css={css.buttonText}>
          <div css={css.buttonTextMenu} className="text">
            Menu
          </div>
          <div css={css.buttonTextClose} className="text">
            Close
          </div>
        </div>
        <div css={css.buttonIcon} className="cursor--magnetic__target">
          <BaseSvg css={[css.icon, css.close]} className="close" src="close" />
          <BaseSvg css={[css.icon]} className="menu" src="menu" />
        </div>
      </button>
    </TheHeaderHeadroom>
  )
}

export default TheHeaderMenuToggle
