import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit"
import transitionReducer from "../store/transition"
import themeReducer from "../store/theme"
import transitionCaseStudyReducer from "../store/transition-case-study"
import menuReducer from "../store/menu"

export default configureStore({
  reducer: {
    transition: transitionReducer,
    theme: themeReducer,
    transitionCaseStudy: transitionCaseStudyReducer,
    menu: menuReducer,
  },
  middleware: getDefaultMiddleware({
    serializableCheck: false,
  }),
})
