import React, { useRef, useEffect } from "react"
import { css } from "@emotion/react"
import { gsap } from "gsap/all"
import { useSelector } from "react-redux"
import { rgba } from "polished"

export default function TheTransitionDefault() {
  const ref = useRef(null)
  const refTimeline = useRef(null)
  const transition = useSelector(state => state.transition.state)
  const transitionCaseStudy = useSelector(
    state => state.transitionCaseStudy.current
  )
  const transitionDuration = useSelector(state => state.transition.duration)

  css.mask = css`
    position: fixed;
    z-index: 5;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    visibility: hidden;
  `

  css.overlay = css`
    position: fixed;
    z-index: 5;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${rgba("#252627", 0.7)};
    visibility: hidden;
  `

  useEffect(() => {
    const element = ref.current
    const mask = element.querySelector(".mask")
    const overlay = element.querySelector(".overlay")

    refTimeline.current = gsap.timeline({ paused: true })

    refTimeline.current
      .fromTo(
        mask,
        { scaleX: 0 },
        {
          scaleX: 1,
          transformOrigin: "left",
          duration: transitionDuration / 1000,
          ease: "power4.inOut",
        },
        0
      )
      .fromTo(
        overlay,
        { autoAlpha: 0 },
        { autoAlpha: 1, duration: transitionDuration / 1000 },
        0
      )
      .set(overlay, { autoAlpha: 0 }, "+=0")
      .fromTo(
        mask,
        { autoAlpha: 1 },
        { autoAlpha: 0, delay: 0.1, duration: transitionDuration / 1000 / 2 },
        "+=0"
      )
  }, [])

  useEffect(() => {
    const timeline = refTimeline.current
    const handleTransitionEntered = () => {
      timeline.play(0)
    }

    if (transition === "exiting" && !transitionCaseStudy) {
      handleTransitionEntered()
    }
  }, [transition, transitionCaseStudy])

  return (
    <div ref={ref}>
      <div css={css.overlay} className="overlay"></div>
      <div css={css.mask} className="mask"></div>
    </div>
  )
}
