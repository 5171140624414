import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { css } from "@emotion/react"
import get from "lodash/get"
import { useSelector } from "react-redux"

import BaseFigureCaseStudy from "../components/BaseFigureCaseStudy"

export default function TheTransitionCaseStudy() {
  const data = useStaticQuery(graphql`
    query TheTransitionCaseStudy {
      allDataJson(filter: { theme: { ne: null } }) {
        edges {
          node {
            fields {
              slug
            }
            title
            tags
            theme
            images {
              foreground {
                src {
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
                  }
                }
              }
              background {
                src {
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
                  }
                }
              }
              featured {
                src {
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
                  }
                }
              }
            }
            backgroundText
          }
        }
      }
    }
  `)

  return (
    <>
      <TheTransitionCaseStudyCollection data={data} />
    </>
  )
}

function TheTransitionCaseStudyCollection({ data }) {
  const items = data.allDataJson.edges.map(item => {
    return {
      slug: item.node.fields.slug,
      title: item.node.title,
      tags: item.node.tags,
      theme: item.node.theme,
      imageForeground: get(item, "node.images.foreground.src"),
      imageBackground: get(item, "node.images.background.src"),
      imageFeatured: get(item, "node.images.featured.src"),
      backgroundText: item.node.backgroundText,
    }
  })
  const transitionCaseStudy = useSelector(
    state => state.transitionCaseStudy.current
  )

  css.section = css`
    position: fixed;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
  `

  return (
    <section css={css.section}>
      {items.map((item, index) => (
        <TheTransitionCaseStudyItem
          key={index}
          {...item}
          transitionRun={
            transitionCaseStudy && transitionCaseStudy.theme === item.theme
              ? transitionCaseStudy
              : null
          }
        />
      ))}
    </section>
  )
}

function TheTransitionCaseStudyItem({
  slug,
  title,
  theme,
  imageForeground,
  imageBackground,
  imageFeatured,
  backgroundText,
  transitionRun,
}) {
  css.section = css`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
  `

  css.figure = css`
    opacity: 0;
    visibility: hidden;
  `

  return (
    <div css={css.section}>
      <BaseFigureCaseStudy
        imageForeground={imageForeground}
        imageBackground={imageBackground}
        theme={theme}
        backgroundText={backgroundText}
        transitionRun={transitionRun}
        transitionLeave={true}
        css={css.figure}
      />
    </div>
  )
}
