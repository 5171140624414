import React, { useEffect, useRef } from "react"
import { css, useTheme } from "@emotion/react"
import { fluidRange } from "polished"
import { gsap, Flip } from "gsap/all"
import { useDispatch } from "react-redux"

import { setCaseStudyCurrent } from "../store/transition-case-study"
import BaseImage from "../components/BaseImage"

if (typeof window !== "undefined") {
  gsap.registerPlugin(Flip)
}

const BaseFigureCaseStudy = props => {
  const {
    imageForeground,
    imageBackground,
    theme,
    backgroundText,
    backgroundTextShow,
    transitionTrigger,
    transitionRun,
    transitionLeave,
    onLoad,
    onStartLoad,
    ...other
  } = props
  const ref = useRef(null)
  const themeGlobal = useTheme()
  const dispatch = useDispatch()

  useEffect(() => {
    if (transitionTrigger) {
      const element = ref.current
      const figureMediaFlip = element.querySelectorAll(".figureMediaFlip")
      const figureMediaFlipStateBefore = Flip.getState(figureMediaFlip)
      dispatch(
        setCaseStudyCurrent({ theme: theme, state: figureMediaFlipStateBefore })
      )
    }
  }, [transitionTrigger, dispatch, theme])

  useEffect(() => {
    if (transitionRun) {
      const element = ref.current
      const figureMediaFlip = element.querySelectorAll(".figureMediaFlip")
      const figureMediaFlipStateBefore = Flip.getState(figureMediaFlip)
      const figureHeadingsItem = element.querySelectorAll(".figureHeadingsItem")
      const timeline = gsap.timeline()
      const flip = Flip.from(transitionRun.state, {
        duration: 1,
        ease: "power2.out",
        targets: figureMediaFlipStateBefore.targets,
      })

      timeline
        .to(element, { autoAlpha: 1, duration: 0.2, ease: "power2.out" }, 0)
        .add(flip)
        .from(
          figureHeadingsItem[0],
          { autoAlpha: 0, x: "-20%", duration: 0.75, ease: "power2.out" },
          "-=0.75"
        )
        .from(
          figureHeadingsItem[1],
          { autoAlpha: 0, x: "20%", duration: 0.75, ease: "power2.out" },
          "-=0.75"
        )
        .from(
          figureHeadingsItem[2],
          { autoAlpha: 0, x: "-20%", duration: 0.75, ease: "power2.out" },
          "-=0.75"
        )
    } else if (!transitionRun && transitionLeave) {
      const element = ref.current
      const timeline = gsap.timeline()

      timeline.to(element, {
        autoAlpha: 0,
        duration: 0,
        delay: 0.1,
        ease: "power2.out",
      })
    }
  }, [transitionRun, transitionLeave])

  css.figure = css`
    overflow: hidden;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    margin: 0;
  `

  css.figureHeadings = css`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `

  css.figureHeadingsItem = css`
    ${themeGlobal.mixins.textOutline}
    ${fluidRange(
      {
        prop: "fontSize",
        fromSize: "200px",
        toSize: "400px",
      },
      themeGlobal.breakpoints.mobile,
      themeGlobal.breakpoints.desktop
    )}
    opacity: 0.2;

    ${!backgroundTextShow &&
    css`
      visibility: hidden;
    `}

    &:nth-of-type(2) {
      opacity: 0.5;
    }
  `

  css.figureMedia = css`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  `

  css.figureMediaBackground = css`
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${themeGlobal.colors[theme].one};
  `

  css.figureMediaImageBackground = css`
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
  `

  css.figureMediaImageBackground = css`
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
  `

  css.figureMediaImageForeground = css`
    width: 100%;
    height: 75%;

    img {
      object-fit: contain !important;
    }
  `

  return (
    <figure ref={ref} css={[css.figure, other.css]} {...other}>
      <div
        css={css.figureMedia}
        className="figureMediaFlip"
        data-flip-id="figureMediaFlip--background"
      >
        <div
          css={css.figureMediaBackground}
          data-flip-id="figureMediaFlip--figureMediaBackground"
        />
        {imageBackground && (
          <BaseImage
            css={css.figureMediaImageBackground}
            src={imageBackground}
            onLoad={onLoad}
            onStartLoad={onStartLoad}
            data-flip-id="figureMediaFlip--figureMediaImageBackground"
          />
        )}
      </div>
      <figcaption css={css.figureHeadings}>
        <div className="figureHeadingsItem" css={css.figureHeadingsItem}>
          {backgroundText}
        </div>
        <div className="figureHeadingsItem" css={css.figureHeadingsItem}>
          {backgroundText}
        </div>
        <div className="figureHeadingsItem" css={css.figureHeadingsItem}>
          {backgroundText}
        </div>
      </figcaption>
      <div
        css={css.figureMedia}
        className="figureMediaFlip"
        data-flip-id="figureMediaFlip--foreground"
      >
        {imageForeground && (
          <BaseImage
            css={css.figureMediaImageForeground}
            src={imageForeground}
            onLoad={onLoad}
            onStartLoad={onStartLoad}
            data-flip-id="figureMediaFlip--figureMediaImageForeground"
          />
        )}
      </div>
    </figure>
  )
}

export default BaseFigureCaseStudy
