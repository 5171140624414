import { createSlice } from "@reduxjs/toolkit"

export const transitionCaseStudySlice = createSlice({
  name: "transitionCaseStudy",
  initialState: {
    current: null,
  },
  reducers: {
    setCaseStudyCurrent: (state, action) => {
      state.current = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setCaseStudyCurrent } = transitionCaseStudySlice.actions

export default transitionCaseStudySlice.reducer
