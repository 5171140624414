import { css } from "@emotion/react"
import { normalize, fluidRange } from "polished"
import { math } from "polished"

import GilroyRegularWoff from "../fonts/gilroy/GilroyRegular.woff"
import GilroyRegularWoff2 from "../fonts/gilroy/GilroyRegular.woff2"
import GilroySemiBoldWoff from "../fonts/gilroy/GilroySemiBold.woff"
import GilroySemiBoldWoff2 from "../fonts/gilroy/GilroySemiBold.woff2"
import GilroyBoldWoff from "../fonts/gilroy/GilroyBold.woff"
import { number } from "../utilities/functions"

let theme = {
  colors: {
    global: {
      dark: "#1C2631",
      light: "#ffffff",
      one: "#878590",
      two: "#0f0e0e",
      error: "#ff4545",
    },
    typeAndTixel: {
      one: "#5945FF",
      oneDifference: "#A6BA00",
      two: "#3D2BD3",
    },
    baskinRobbins: {
      one: "#FFDCEE",
      two: "#FBB9DA",
      three: "#E4F8FF",
      block: "#FFCEE7",
    },
    meetAndGreens: {
      one: "#39B578",
      two: "#86DBB0",
      block: "#EFEAEA",
    },
    alphaDigital: {
      one: "#62CCC0",
      two: "#89DDD3",
      block: "#397F85",
    },
    strangerThings: {
      one: "#030303",
      two: "#030303",
      block: "#110E20",
    },
    pep: {
      one: "#0084CD",
      two: "#98BEE2",
      block: "#9EDDFF",
    },
    roadSet: {
      one: "#FED883",
      two: "#EDCF92",
      block: "#0D3B66",
    },
    moveWithUs: {
      one: "#9800ED",
      two: "#EAC6FF",
      block: "#DCFFA9",
    },
    misoHungry: {
      one: "#003C46",
      two: "#9D8EFF",
      three: "#9D8EFF",
      block: "#245861",
    },
    uniqYou: {
      one: "#00492C",
      two: "#CBD0EB",
      three: "#CBD0EB",
      block: "#043928",
    },
    ghostbusters: {
      one: "#0E101E",
      two: "#CBD0EB",
      block: "#0E101E",
    },
    oca: {
      one: "#A7D6C8",
      two: "#F48769",
      three: "#A7D6C8",
      block: "#A7D6C8",
    },
    powerUp: {
      one: "#EA6760",
      two: "#282828",
      block: "#404040",
    },
    hotelTransylvania: {
      one: "#412A7A",
      two: "#282828",
      block: "#412A7A",
    },
  },
  breakpoints: {
    mobile: "414px",
    tablet: "768px",
    laptopSmall: "1280px",
    laptop: "1440px",
    desktop: "1920px",
  },
  fontFamilies: {
    sansSerif: "'Gilroy', sans-serif",
  },
  fontWeight: {
    normal: 400,
    semiBold: 600,
    bold: 700,
  },
  grid: {
    container: "1280px",
    gutter: "40px",
  },
  motion: {
    scrollTrigger: {
      start: "top 66.666%",
    },
    stagger: {
      each: 0.1,
    },
    ease: "power4.inOut",
  },
}

const gridViewport = number(theme.breakpoints.desktop)
const gridContainer = number(theme.grid.container)
const gridContainerGutter = number(theme.grid.gutter)
const gridContainerGutters = gridContainerGutter * 2
const gridGuttersTotal = (12 + 1 - 2) * gridContainerGutter
const gridColumn = (gridContainer - gridGuttersTotal) / 12
const gridContainerTotal = gridContainer + gridContainerGutters
const gridContainerBleed = (gridViewport - gridContainer) / 2

theme = {
  ...theme,
  mixins: {
    global: css`
      ${normalize()}

      @font-face {
        font-family: "Gilroy";
        font-weight: 400;
        font-display: swap;
        src: url(${GilroyRegularWoff}) format("woff2"),
          url(${GilroyRegularWoff2}) format("woff");
      }
      @font-face {
        font-family: "Gilroy";
        font-weight: 600;
        font-display: swap;
        src: url(${GilroySemiBoldWoff}) format("woff2"),
          url(${GilroySemiBoldWoff2}) format("woff");
      }
      @font-face {
        font-family: "Gilroy";
        font-weight: 700;
        font-display: swap;
        src: url(${GilroyBoldWoff}) format("woff2");
      }

      *,
      :after,
      :before {
        box-sizing: border-box;
      }

      html {
        background-color: ${theme.colors.global.light};
      }

      body {
        font-family: ${theme.fontFamilies.sansSerif}, sans-serif;
        font-weight: ${theme.fontWeight.normal};
        color: ${theme.colors.global.dark};
        background-color: ${theme.colors.global.light};
      }

      img,
      svg,
      video {
        max-width: 100%;
        height: auto;
      }

      .app {
        position: relative;
        overflow: hidden;
      }

      .cursor--active .cursor {
        pointer-events: auto;
      }
    `,
    container: {
      xl: css`
        max-width: ${theme.grid.container};
        margin: 0 auto;
        padding: 0 ${theme.grid.gutter};
        box-sizing: content-box;
      `,
    },
    grid: {
      default: css`
        display: grid;
        grid-template-columns: repeat(1, minmax(0, 1fr));
        grid-column-gap: ${theme.grid.gutter};

        @media (min-width: ${theme.breakpoints.tablet}) {
          grid-template-columns: repeat(12, minmax(0, 1fr));
        }
      `,
      bleedFull: css`
        display: grid;
        grid-template-columns: minmax(0, 1fr);
        grid-column-gap: ${gridContainerGutter}px;
        justify-content: center;

        @media (min-width: ${theme.breakpoints.tablet}) {
          grid-template-columns:
            minmax(0, 1fr)
            minmax(0, 1fr)
            repeat(12, minmax(auto, ${gridColumn}px))
            minmax(0, 1fr)
            minmax(0, 1fr);
          margin: 0 ${math(`${theme.grid.gutter} * -1`)};
        }

        @media (min-width: ${gridContainerTotal}px) {
          grid-template-columns:
            minmax(
              0,
              ${math(`${gridContainerBleed} - 170px - ${theme.grid.gutter}`)}
            )
            minmax(0, 170px)
            repeat(12, ${gridColumn}px)
            minmax(0, 170px)
            minmax(
              0,
              ${math(`${gridContainerBleed} - 170px - ${theme.grid.gutter}`)}
            );
        }
      `,
      bleedPartial: css`
        display: grid;
        grid-template-columns: minmax(0, 1fr);
        grid-column-gap: ${gridContainerGutter}px;
        justify-content: center;

        @media (min-width: ${theme.breakpoints.tablet}) {
          grid-template-columns:
            minmax(0, 1fr)
            repeat(12, minmax(auto, ${gridColumn}px))
            minmax(0, 1fr);
          margin: 0 -${theme.grid.gutter};
        }

        @media (min-width: ${gridContainerTotal}px) {
          grid-template-columns:
            minmax(0, 170px)
            repeat(12, ${gridColumn}px)
            minmax(0, 170px);
        }
      `,
    },
    textOutline: css`
      white-space: nowrap;
      line-height: 1;
      font-weight: ${theme.fontWeight.semiBold};
      -webkit-text-stroke: 3px ${theme.colors.global.light};
      color: transparent;
    `,
    button: css`
      display: inline-flex;
      justify-content: center;
      align-items: center;
      border: 0;
      margin: 0;
      padding: 0;
      text-align: center;
      background-color: transparent;
      cursor: pointer;
      appearance: none;
    `,
    typography: {
      subheading1: css`
        margin-bottom: 20px;
        font-size: 14px;
        font-weight: ${theme.fontWeight.semiBold};

        @media (min-width: ${theme.breakpoints.laptop}) {
          position: absolute;
          top: 0;
          right: 100%;
          margin-top: 10px;
          margin-right: 50px;
        }
      `,
      heading1: css`
        ${fluidRange(
          {
            prop: "fontSize",
            fromSize: "46px",
            toSize: "60px",
          },
          theme.breakpoints.mobile,
          theme.breakpoints.desktop
        )}
        margin: 0;
        font-weight: ${theme.fontWeight.semiBold};
      `,
      heading1Alt: css`
        ${fluidRange(
          {
            prop: "fontSize",
            fromSize: "40px",
            toSize: "60px",
          },
          theme.breakpoints.mobile,
          theme.breakpoints.desktop
        )}
        margin: 0;
        font-weight: ${theme.fontWeight.semiBold};
      `,
      text1: css`
        ${fluidRange(
          {
            prop: "fontSize",
            fromSize: "14px",
            toSize: "16px",
          },
          theme.breakpoints.mobile,
          theme.breakpoints.desktop
        )}
        ${fluidRange(
          {
            prop: "lineHeight",
            fromSize: "24px",
            toSize: "37px",
          },
          theme.breakpoints.mobile,
          theme.breakpoints.desktop
        )}
      `,
    },
  },
}

export default theme
