/**
 * TheLayout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { ThemeProvider, Global, css } from "@emotion/react"
import { Provider } from "react-redux"

import store from "../utilities/store"
import theme from "../utilities/theme"
import TheHeader from "../components/TheHeader"
import TheCursor from "../components/TheCursor"
import TheTransitionCaseStudy from "../components/TheTransitionCaseStudy"
import TheTransitionDefault from "../components/TheTransitionDefault"
import Transition from "../components/Transition"

const TheLayout = ({ children, location }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <Provider store={store}>
      <Global
        styles={css`
          ${theme.mixins.global}
        `}
      />
      <ThemeProvider theme={theme}>
        <div className="app">
          <TheHeader siteTitle={data.site.siteMetadata?.title || `Title`} />
          <main>
            <Transition location={location}>{children}</Transition>
          </main>
          <TheTransitionCaseStudy />
          <TheTransitionDefault />
          <TheCursor />
        </div>
      </ThemeProvider>
    </Provider>
  )
}

export default TheLayout
