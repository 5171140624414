import React from "react"
import { css, useTheme } from "@emotion/react"
import { fluidRange } from "polished"
import Headroom from "react-headroom"

const TheHeaderHeadroom = props => {
  const { children, alignmentHorizontal, disable, ...other } = props
  const theme = useTheme()

  css.headroom = css`
    height: 0 !important;

    .headroom {
      /* top: 0;
      left: 0;
      right: 0;
      z-index: 1; */

      position: fixed;
      z-index: 4;
      top: 0;
      right: ${alignmentHorizontal === 'right' ? '0' : 'auto'};
      left: ${alignmentHorizontal === 'left' ? '0' : 'auto'};
      ${fluidRange(
        {
          prop: "padding",
          fromSize: "40px",
          toSize: "50px",
        },
        theme.breakpoints.mobile,
        theme.breakpoints.desktop
      )}
      color: ${theme.colors.typeAndTixel.oneDifference};
      mix-blend-mode: difference;

      transition: transform 200ms ease-out;

      ${disable && css`
        position: absolute;
      `}
    }
    .headroom--unfixed {
      /* position: fixed; */
      transform: translateY(0);
    }
    .headroom--scrolled {
      transform: translateY(-100%);
    }
    .headroom--unpinned {
      /* position: fixed; */
      transform: translateY(-100%);
    }
    .headroom--pinned {
      /* position: fixed; */
      transform: translateY(0%);
    }
  `

  return (
    <Headroom css={css.headroom} {...other} disableInlineStyles disable={disable}>
      {children}
    </Headroom>
  )
}

TheHeaderHeadroom.defaultProps = {
  alignmentHorizontal: 'left',
  disable: false,
}

export default TheHeaderHeadroom
