import { createSlice } from "@reduxjs/toolkit"

export const transitionSlice = createSlice({
  name: "transition",
  initialState: {
    state: "",
    duration: 2000,
    durationDefault: 2000,
  },
  reducers: {
    setState: (state, action) => {
      state.state = action.payload
    },
    setDuration: (state, action) => {
      state.duration = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setState, setDuration } = transitionSlice.actions

export default transitionSlice.reducer
