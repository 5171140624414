import { css } from "@emotion/react"
import { gsap, ScrollTrigger } from "gsap/all"
import kebabCase from "lodash/kebabCase"

export const percentage = (value, total, unit = "%") => {
  return `${(value / total) * 100}${unit}`
}

export const number = (value, unit = "px") => {
  return Number(value.replace(unit, ""))
}

export const randomInteger = (min, max) => {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min) + min)
}

export const lerpCalc = (min, max, minScreen, maxScreen) => {
  const unit = min.replace(parseFloat(min), "")
  const minInt = parseFloat(min)
  const maxInt = parseFloat(max)
  const minScreenInt = parseFloat(minScreen)
  const maxScreenInt = parseFloat(maxScreen)

  let slope = (minInt - maxInt) / (minScreenInt - maxScreenInt)

  let base = maxInt - slope * maxScreenInt

  const value = `calc(${base.toFixed(2)}${unit || ""} + ${(100 * slope).toFixed(
    2
  )}vw)`

  return value
}

export const fluid = (cssProp, minScreen, maxScreen) => {
  const property = kebabCase(cssProp.prop)
  const min = cssProp.fromSize
  const max = cssProp.toSize
  return css`
    ${property}: clamp(${min}, ${lerpCalc(
      min,
      max,
      minScreen,
      maxScreen
    )}, ${max})
  `
}

export const timelineScrubEqualize = trigger => {
  const triggerDimensions = trigger.getBoundingClientRect()
  const timelineDuration = 1
  const scrollTriggerStart = triggerDimensions.top
  const scrollTriggerEnd = triggerDimensions.bottom
  const windowHeight = window.innerHeight
  const scrollTriggerDistanceScrub = window.innerHeight * 0.25
  const scrollTriggerDistanceTotal = scrollTriggerEnd - scrollTriggerStart
  const scrollTriggerDistanceSurplus =
    scrollTriggerDistanceTotal - scrollTriggerDistanceScrub * 2
  const scrollTriggerDistanceScrubPercentage =
    scrollTriggerDistanceScrub / scrollTriggerDistanceTotal
  const scrollTriggerDistanceSurplusPercentage =
    scrollTriggerDistanceSurplus / scrollTriggerDistanceTotal
  const timelineDurationScrub =
    timelineDuration * scrollTriggerDistanceScrubPercentage
  const timelineDurationSurplus =
    timelineDuration * scrollTriggerDistanceSurplusPercentage

  return {
    timelineDurationScrub: timelineDurationScrub,
    timelineDurationSurplus: timelineDurationSurplus,
  }
}
