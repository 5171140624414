import React, { useRef, useEffect, useLayoutEffect } from "react"
import { css, useTheme } from "@emotion/react"
import { gsap } from "gsap/all"
import { useSelector } from "react-redux"

const TheCursor = () => {
  const theme = useTheme()
  const ref = useRef(null)
  const refPosition = useRef({ x: null, y: null })
  const refActive = useRef(false)
  // const refPosition = useRef({ x: window.innerWidth / 2, y: window.innerHeight / 2 })
  const transition = useSelector(state => state.transition.state)
  const transitionDuration = useSelector(state => state.transition.duration)

  useEffect(() => {
    const element = ref.current
    // const position = { x: window.innerWidth / 2, y: window.innerHeight / 2 }
    const mouse = { x: refPosition.current.x, y: refPosition.current.y }
    const speed = 0.2
    const fpms = 60 / 1000

    const xSet = gsap.quickSetter(element, "x", "px")
    const ySet = gsap.quickSetter(element, "y", "px")
    const handleTicker = (time, deltaTime) => {
      const active = refActive.current

      if (!active) {
        const delta = deltaTime * fpms
        const dt = 1.0 - Math.pow(1.0 - speed, delta)

        refPosition.current.x += (mouse.x - refPosition.current.x) * dt
        refPosition.current.y += (mouse.y - refPosition.current.y) * dt
        xSet(refPosition.current.x)
        ySet(refPosition.current.y)
      }
    }

    gsap.set(element, { xPercent: -50, yPercent: -50 })

    window.addEventListener("mousemove", event => {
      mouse.x = event.x
      mouse.y = event.y
    })

    gsap.ticker.add(handleTicker)

    return () => {
      gsap.ticker.remove(handleTicker)
    }
  }, [])

  useEffect(() => {
    const element = ref.current
    const items = document.querySelectorAll(".cursor--magnetic")

    const parallaxCursor = (e, parent, target, movement) => {
      var rect = target
        ? target.getBoundingClientRect()
        : parent.getBoundingClientRect()
      var relX = e.clientX - rect.left
      var relY = e.clientY - rect.top
      refPosition.current.x =
        rect.left + rect.width / 2 + (relX - rect.width / 2) / movement
      refPosition.current.y =
        rect.top + rect.height / 2 + (relY - rect.height / 2) / movement
      gsap.to(element, {
        x: refPosition.current.x,
        y: refPosition.current.y,
        duration: 0.3,
      })
    }
    const parallaxIt = (e, parent, target, movement) => {
      var rect = target
        ? target.getBoundingClientRect()
        : parent.getBoundingClientRect()
      var relX = e.clientX - rect.left
      var relY = e.clientY - rect.top

      gsap.to(target, {
        x: ((relX - rect.width / 2) / rect.width) * movement,
        y: ((relY - rect.height / 2) / rect.height) * movement,
        duration: 0.3,
      })
    }
    const unparallaxIt = (e, parent, target) => {
      gsap.to(target, {
        x: 0,
        y: 0,
        duration: 0.3,
      })
    }
    const handleMouseMove = event => {
      const parent = event.target
      const target = event.target.querySelector(".cursor--magnetic__target")

      parallaxCursor(event, parent, target, 16)
      parallaxIt(event, parent, target, 3)
    }
    const handleMouseEnter = event => {
      refActive.current = true
    }
    const handleMouseLeave = event => {
      const parent = event.target
      const target = event.target.querySelector(".cursor--magnetic__target")

      unparallaxIt(event, parent, target)

      refActive.current = false
    }

    items.forEach(item => {
      item.addEventListener("mouseenter", handleMouseEnter)
      item.addEventListener("mouseleave", handleMouseLeave)
      item.addEventListener("mousemove", handleMouseMove)
    })

    return () => {
      items.forEach(item => {
        item.removeEventListener("mouseenter", handleMouseEnter)
        item.removeEventListener("mouseleave", handleMouseLeave)
        item.removeEventListener("mousemove", handleMouseMove)
      })
    }
  }, [])

  useEffect(() => {
    const element = ref.current
    let items = []
    const handleMouseEnter = () => {
      gsap.to(element, {
        scale: 4,
        duration: 0.25,
        ease: "power2.inOut",
      })
    }
    const handleMouseLeave = () => {
      gsap.to(element, {
        scale: 1,
        duration: 0.25,
        ease: "power2.inOut",
      })
    }
    const handleTransitionEntered = () => {
      items = document.querySelectorAll(".cursor")

      items.forEach(item => {
        item.addEventListener("mouseenter", handleMouseEnter)
        item.addEventListener("mouseleave", handleMouseLeave)
      })
    }

    if (transition === "entered") {
      handleTransitionEntered()
    }

    return () => {
      items.forEach(item => {
        item.removeEventListener("mouseenter", handleMouseEnter)
        item.removeEventListener("mouseleave", handleMouseLeave)
      })
    }
  }, [transition])

  useEffect(() => {
    const element = ref.current
    const text = element.querySelectorAll(".text")
    const handleTransitionExiting = () => {
      gsap.to(element, {
        scale: 1,
        duration: 0.6,
        ease: "power3.out",
      })
      gsap.to(text, {
        autoAlpha: 0,
        duration: 0.6,
        ease: "power3.out",
      })
    }

    if (transition === "exiting") {
      handleTransitionExiting()
    }

    return () => {}
  }, [transition])

  useEffect(() => {
    const element = ref.current
    const text = element.querySelectorAll(".text")

    let items = []
    const handleMouseEnter = () => {
      gsap.to(text, {
        autoAlpha: 1,
        duration: 0.6,
        ease: "power3.out",
      })
    }
    const handleMouseLeave = () => {
      gsap.to(text, {
        autoAlpha: 0,
        duration: 0.6,
        ease: "power3.out",
      })
    }
    const handleTransitionEntered = () => {
      items = document.querySelectorAll(".cursor--case-study")

      items.forEach(item => {
        item.addEventListener("mouseenter", handleMouseEnter)
        item.addEventListener("mouseleave", handleMouseLeave)
      })
    }

    if (transition === "entered") {
      handleTransitionEntered()
    }

    return () => {
      items.forEach(item => {
        item.removeEventListener("mouseenter", handleMouseEnter)
        item.removeEventListener("mouseleave", handleMouseLeave)
      })
    }
  }, [transition])

  css.cursor = css`
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: ${theme.colors.typeAndTixel.oneDifference};
    mix-blend-mode: difference;
    pointer-events: none;

    @media (max-width: ${theme.breakpoints.laptopSmall}) {
      display: none;
    }
  `

  css.text = css`
    font-size: ${16 / 4}px;
    font-weight: ${theme.fontWeight.bold};
    line-height: 1;
    text-align: center;
    visibility: hidden;
  `

  return (
    <div ref={ref} css={css.cursor}>
      <div css={css.text} className="text">
        view <br /> work
      </div>
    </div>
  )
}

export default TheCursor
